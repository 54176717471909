import { DEBUGGING_MODE } from 'common/global-constants'
import { TRADE_STATUS, NULL_ADDRESS, TRADE_EVENT, GRAPHQL_TXN_KEY } from 'EthereumApp/app-constants'
import { sameAddresses, currentTimestamp } from 'common/utils'
import ChainTalk from 'EthereumApp/chaintalk'
import { diff } from 'deep-object-diff'
const _ = require('underscore')
const BigNumber = require('bignumber.js')

// filter our some unnecessary fields
export const storedTradeObject = (tradeData, onChain = false) => {
  const trade = _.pick(tradeData,
    'tradeId', 'seller', 'useFreeTxn', 'buyer',
    'collection', 'fee', 'feeDiscount', 'price',
    'status', 'txns', 'createdAt', 'updatedAt',
    'userNotifiedAt'
  )

  // trade loaded onchain, need to fill some fields to make it look like graphql result
  if (onChain) {
    const _collection = trade.collection.map(c => {
      return {
        contractAddress: c.contractAddress.toLowerCase(),
        nftType: c.nftType,
        tokenIds: c.tokenIds,
        amounts: c.amounts
      }
    })
    trade.collection = _collection

    trade.createdAt = currentTimestamp().toString()
    trade.updatedAt = trade.createdAt

    // cannot build txns, just let it blank
    trade.txns = []

    DEBUGGING_MODE && console.log('storedTradeObject final result:', trade)
  }

  return trade
}

export const isSeller = (trade, viewer) => {
  return trade && sameAddresses(trade.seller, viewer)
}

export const isBuyer = (trade, viewer) => {
  return trade && sameAddresses(trade.buyer, viewer)
}

export const isGuest = (trade, viewer) => {
  return trade && !sameAddresses(trade.seller, viewer) && !sameAddresses(trade.buyer, viewer)
}

export const nullBuyer = (trade) => {
  return trade && sameAddresses(trade.buyer, NULL_ADDRESS)
}

// is null address or empty address?
export const nullAddress = (address) => {
  return address == null || sameAddresses(address, NULL_ADDRESS)
}

// is just created
export const isWaiting = (trade) => {
  return parseInt(trade?.status) === TRADE_STATUS.LISTED
}

// is transfer completed?
export const isCompleted = (trade) => {
  return parseInt(trade?.status) === TRADE_STATUS.COMPLETED
}

// is transfer cancelled?
export const isCancelled = (trade) => {
  return parseInt(trade?.status) === TRADE_STATUS.CANCELLED
}

// is this address involved in the transfer
export const isInvolved = (trade, viewer) => {
  return trade && viewer && (isSeller(trade, viewer) || isBuyer(trade, viewer))
}

// is buyer address match trade buyer?
export const matchBuyer = (trade, viewer) => {
  return (sameAddresses(trade.buyer, NULL_ADDRESS) && viewer) || sameAddresses(trade.buyer, viewer)
}

// user can buy?
export const canBuy = (trade, viewer) => {
  return isWaiting(trade) && !isSeller(trade, viewer) && matchBuyer(trade, viewer)
}

// seller can cancel?
export const canCancel = (trade, viewer) => {
  return isWaiting(trade) && isSeller(trade, viewer)
}

// custom status message
export const displayStatus = (trade, viewer = null) => {
  switch (trade.status) {
    case TRADE_STATUS.COMPLETED:
      return isBuyer(trade, viewer) ?
        TRADE_STATUS.BOUGHT
        :
        isSeller(trade, viewer) ? TRADE_STATUS.SOLD : TRADE_STATUS.COMPLETED

    default: {
      return trade.status
    }
  }
}

// build trade from transaction receipt for storing in redux states
export const buildStoredTradeFromReceipt = async (tradeData, receipt, eventName) => {
  if (!tradeData || !receipt || !receipt.logs) return
  DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt receipt:', receipt)
  DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt receipt logs:', receipt.logs)

  const blockHash = receipt.blockHash
  const log = receipt.logs ? receipt.logs.find(l => l.event === eventName) : null
  const tradeId = log?.args?.tradeId?.toString()
  const txn = receipt.transactionHash
  const caller = receipt.from

  DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt:', tradeId, txn, blockHash, caller)
  if (!tradeId || !txn || !blockHash || !caller) return
  DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt log args:', log.args)

  // get receipt's block timestamp for storing createdAt, updatedAt
  const timestamp = await ChainTalk.getBlock(blockHash)
    .then(block => {
      DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt block:', block)
      return block?.timestamp?.toString()
    })
    .catch(error => {
      DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt block:', error)
      return currentTimestamp().toString()
    })

  // build status & transactionHash
  let trade = {
    ...tradeData,
    txns: tradeData.txns ? tradeData.txns.filter(txn => txn.txHash != null) : []
  }

  switch (eventName) {
    case TRADE_EVENT.NEW_TRADE_CREATED: {
      // use trade return from receipt log
      DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt newTrade:', log.args.newTrade)
      const _tmpTrade = storedTradeObject(log.args.newTrade)

      // build collection
      const collection = _tmpTrade?.collection?.map(c => {
        return {
          contractAddress: c.contractAddress,
          nftType: c.nftType,
          tokenIds: c.tokenIds,
          amounts: c.amounts
        }
      })

      // created trade
      trade = {
        ..._tmpTrade,
        status: parseInt(_tmpTrade.status),
        collection: collection,
        txns: [{ name: GRAPHQL_TXN_KEY.LISTED, txHash: txn, createdAt: timestamp }],
        createdAt: timestamp,
      }

      break
    }

    case TRADE_EVENT.TRADE_CANCELLED:
    case TRADE_EVENT.TRADE_COMPLETED: {
      const newStatus = log.args.newStatus
      if (!newStatus) return
      trade.status = parseInt(newStatus)

      if (isCancelled(trade))
        trade.txns.push({ name: GRAPHQL_TXN_KEY.CANCELLED, txHash: txn, createdAt: timestamp })

      if (isCompleted(trade)) {
        trade.buyer = caller
        trade.txns.push({ name: GRAPHQL_TXN_KEY.COMPLETED, txHash: txn, createdAt: timestamp })
      }

      break
    }

    case TRADE_EVENT.TRADE_PRICE_CHANGED: {
      trade.price = log.args.newPrice.toString()
      trade.txns.push({ name: GRAPHQL_TXN_KEY.PRICE_CHANGED, txHash: txn, createdAt: timestamp })

      if (!trade.price) return
      break
    }

    case TRADE_EVENT.TRADE_BUYER_CHANGED: {
      trade.buyer = log.args.newBuyer
      trade.txns.push({ name: GRAPHQL_TXN_KEY.BUYER_CHANGED, txHash: txn, createdAt: timestamp })
      if (!trade.buyer) return

      break
    }

    default:
      break
  }

  // tradeId
  if (!trade.tradeId) trade.tradeId = tradeId

  // receipt block's timestamp
  trade.updatedAt = timestamp
  DEBUGGING_MODE && console.log('buildStoredTradeFromReceipt final result:', trade)

  return trade
}

// build balanceRef
export const buildCollectionBalanceRef = (collection) => {
  DEBUGGING_MODE && console.log('buildCollectionBalanceRef collection:', collection)

  const balanceRef = {}
  for(let i = 0; i < collection.tokenIds.length; i++) {
    if (!balanceRef[collection.tokenIds[i]])
      balanceRef[collection.tokenIds[i]] = new BigNumber(0)

    // for case ids [1, 2, 1] amounts [1, 1, 1]
    // => balanceRef {1: 2, 2: 1}
    balanceRef[collection.tokenIds[i]] = balanceRef[collection.tokenIds[i]].plus(
      collection.amounts[i] ? collection.amounts[i] : 1
    )
  }

  DEBUGGING_MODE && console.log('buildCollectionBalanceRef balanceRef:', balanceRef)
  return balanceRef
}

// check new data are different from source trade
export const tradeDataChanged = (srcTrade, newData) => {
  DEBUGGING_MODE && console.log('tradeDataChanged srcTrade:', srcTrade)
  const keys = Object.keys(newData)
  const oldData = _.pick(srcTrade, keys)
  const changed = diff(oldData, newData)
  DEBUGGING_MODE && console.log('tradeDataChanged old:', oldData, 'new:', newData, 'changed:', changed)

  return !_.isEmpty(changed)
}

// is current trade outdated?
export const isOutdated = (currentTrade, loadedTrade) => {
  if (!loadedTrade)
    return false

  if (currentTrade && currentTrade.tradeId !== loadedTrade.tradeId)
    return false

  return !currentTrade?.updatedAt || parseInt(currentTrade.updatedAt) < parseInt(loadedTrade.updatedAt)
}

// is user notified about last update?
export const isUserNotified = (cachedTrade) => {
  return parseInt(cachedTrade.userNotifiedAt) === parseInt(cachedTrade.updatedAt)
}
