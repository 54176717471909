import { CHAINS, CHAIN_IDS, ENABLED_CHAINS, DEFAULT_CHAIN, DEBUGGING_MODE } from 'common/global-constants'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactGA from 'react-ga4'
const web3 = require('web3')

TimeAgo.addDefaultLocale(en)

const timeAgo = new TimeAgo('en-US')

// common helpers

export const getReactGA = () => {
  const gaMeasurementId = process?.env?.REACT_APP_GA_MEASUREMENT_ID
  DEBUGGING_MODE && console.log('GA MeasurementId:', gaMeasurementId)
  if (gaMeasurementId)
    ReactGA.initialize(gaMeasurementId, {
      gaOptions: {
        debug_mode: true,
      },
      gtagOptions: {
        debug_mode: true,
      },
    })
  return ReactGA
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const delay = async (func, ms = 1000) => {
  await sleep(ms)
  return func
}

export const copyToClipboard = (str) => {
  navigator.clipboard.writeText(str)
}

export const getRelativeTime = (dateString) => {
  return timeAgo.format(Date.parse(dateString))
}

// ms
export const isExpired = (expiredAtMs) => {
  return expiredAtMs && Date.now() > expiredAtMs
}

export const showDate = (epoch) => {
  if (!epoch) return
  const s = epoch.toString()
  const i = parseInt(epoch)
  return new Date(s.length === 10 ? i * 1000 : i).toLocaleString()
}

export const capitalizeStr = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const toHex = (number) => {
  return web3.utils.toHex(number)
}

export const fromHex = (hexString) => {
  return parseInt(hexString, 16)
}

export const getCurrentChain = () => {
  //console.log('Current hostname:', window.location.hostname)
  const parts = window.location.hostname.split('.')
  const subdomain = parts[0]?.toLowerCase()
  //console.log('Current subdomain:', subdomain)

  // selected chain is in allowed chain list
  if (ENABLED_CHAINS?.includes(CHAIN_IDS[subdomain]))
    return subdomain

  //console.log('Use default chain:', DEFAULT_CHAIN)
  // valid selected chain but it's not allowed
  return DEFAULT_CHAIN
}

export const buildAppUrlByChain = (toChain) => {
  const switchToChain = ENABLED_CHAINS?.includes(CHAIN_IDS[toChain]) ? toChain : DEFAULT_CHAIN
  const protocol = window.location.protocol
  const host = window.location.host
  const parts = host.split('.')
  const currentSubdomain = parts[0] // current subdomain

  // currently on url WITH prefix chain name
  if (Object.values(CHAINS).includes(currentSubdomain)) {
    if (switchToChain === DEFAULT_CHAIN)
      parts.splice(0, 1) // remove the prefix => change to default chain
    else
      parts[0] = switchToChain // replace the prefix

  // currently on url WITHOUT prefix chain name (on default chain)
  } else {
    parts.splice(0, 0, switchToChain) // insert the prefix
  }

  const url = protocol + '//' + parts.join('.')
  console.log('App url:', url)

  return url
}

export const currentTimestamp = () => {
  return Math.floor(Date.now() / 1000)
}

export const truncateAddress = (address, charLength = 4, tailOnly = false) => {
  if (!address) return ''
  return `${address?.slice(0, tailOnly ? 2 : 2+charLength)}...${address.slice(-charLength)}`
}

export const truncateText = (text, charLength = 4) => {
  if (!text || text.length <= charLength) return text
  return text.slice(0, charLength) + '...'
}
